<template>
  <div>
    <div class="dashboard__container pb-2 pt-2" v-if="eventInfo && eventInfo.id">
      <span class="flex align-start flex-wrap">
        <router-link :to="`/orders/${$route.params.id}/staffing/proximity`">
          <button class="btn btn__small mr-3 mb-2" v-bind:class="{ 'btn__primary': isProximity, 'btn__dark btn__small': !isProximity }">Nearby Users</button>
        </router-link>
        <router-link :to="`/orders/${$route.params.id}/staffing/groups`">
          <button class="btn btn__small mr-3 mb-2" v-bind:class="{ 'btn__primary': isGroups, 'btn__dark btn__small': !isGroups }">Groups</button>
        </router-link>
        <!-- <router-link :to="`/orders/${$route.params.id}/staffing/applicants`" v-if="eventInfo.shiftType && eventInfo.shiftType == 'Gig'">
          <button class="btn btn__small mr-3 mb-2" v-bind:class="{ 'btn__primary': isApplicants, 'btn__dark btn__small': !isApplicants }">Applicants</button>
        </router-link> -->
        <router-link :to="`/orders/${$route.params.id}/staffing/pipeline`" v-if="eventInfo.shiftType && eventInfo.shiftType == 'Job'">
          <button class="btn btn__small mr-3 mb-2" v-bind:class="{ 'btn__primary': isPipeline, 'btn__dark btn__small': !isPipeline }">Pipeline <i class="fa-regular fa-filter ml-2"></i></button>
        </router-link>
        <router-link :to="`/orders/${$route.params.id}/staffing/placements`">
          <button class="btn btn__small mr-3 mb-2" v-bind:class="{ 'btn__primary': isPlacement, 'btn__dark btn__small': !isPlacement }">Placements</button>
        </router-link>
        <router-link :to="`/orders/${$route.params.id}/staffing/drops`">
          <button class="btn btn__small mr-3 mb-2" v-bind:class="{ 'btn__primary': isDrops, 'btn__dark btn__small': !isDrops }">Drops</button>
        </router-link>
        <router-link :to="`/orders/${$route.params.id}/staffing/email`">
          <button class="btn btn__small mr-3 mb-2" v-bind:class="{ 'btn__primary': isEmail, 'btn__dark btn__small': !isEmail }">Preview Email</button>
        </router-link>
        
       
      </span>
    </div>
    <div class="dashboard__container pb-0 pt-0">
      <Loader v-if="!eventInfo || !eventInfo.id" />
      <!-- <div class="dashboard__container--header" v-if="(eventShifts && eventShifts.length > 0) && (!eventInfo.workerType || eventInfo.workerType != 'Employees') && (isApplicants || isPlacement)">
        <div class="whiteBack mb-5" style="width:50%; min-width: 20rem;">
          <label for="pickDay" class="mb-2">Choose Day or Start Date:</label>
          <v-select
            label="title" 
            :options="eventShiftsDays"
            clearable
            v-model="activeDay" 
            @input="setActiveDay"
            >
            <template #option="option">
              {{option.title | moment("dddd, MMM Do, YYYY") }}
            </template>
          </v-select>
        </div>

      </div> -->
      <router-view :key="$route.params.id" :eventInfo="eventInfo" :eventShifts="eventShifts" :eventShiftsDays="eventShiftsDays" :eventUsers="eventUsers" />
    </div>
  </div>
</template>


<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import router from '@/router'

export default {
  name: 'staffingHome',
  data() {
    return {
      activeDay: ''
    }
  },
  computed: {
    ...mapState(['currentUser', 'userProfile', 'eventInfo', 'eventShifts', 'eventShiftsDays', 'eventUsers']),
    isPlacement() {
      return this.$route.name == 'staffingPlacements';
    },
    isDrops() {
      return this.$route.name == 'staffingDrops';
    },
    isPipeline() {
      return this.$route.name == 'staffingPipeline';
    },
    isProximity() {
      return this.$route.name == 'staffingProximity';
    },
    isApplicants() {
      return this.$route.name == 'staffingApplicants';
    },
    isEmail() {
      return this.$route.name == 'staffingEmail';
    },
    isGroups() {
      return this.$route.name == 'staffingGroups';
    }
  },
  // created () {
  //   if (!this.eventInfo || (this.eventInfo && !this.eventInfo.id)) {
  //     console.log("getEventFromId from EventHome")
  //     this.$store.dispatch("getEventFromId", this.$route.params.id);
  //   }
  // },
  // mounted() {
  //   if (!this.eventShifts || this.eventShifts.length < 1) {
  //     this.$store.dispatch("getEventShiftsState", this.$route.params.id)
  //   }
  //   if (!this.eventShiftsDays || this.eventShiftsDays.length < 1) {
  //     this.$store.dispatch("getEventShiftsDays", this.$route.params.id)
  //   }
  //   if (!this.eventShifts || this.eventShifts.length < 1) {
  //     this.$store.dispatch("getEventShifts", this.$route.params.id)
  //   }
  // },
  components: {
    Loader,
  },
  methods: {
    setActiveDay(day) {
      this.performingRequest = true
      console.log(day)
      if(day) {
        this.$store.dispatch('setActiveDay', day),
        this.$store.dispatch('getEventUsersByDay', {
          event: this.eventInfo,
          day: day
        })
        // this.$store.dispatch('getEventUsersRef', {
        //   event: this.eventInfo.id,
        //   day: day
        // })
      } else {
        this.$store.dispatch('clearEventUsersAndRef'),
        this.$store.dispatch('setActiveDay', null)
        // this.$store.dispatch("clearEventAssignmentsByDay")
      }
      this.performingRequest = false
    },
    // async addWatch() {
    //   await this.$store.dispatch('watchOrder', this.$route.params.id)
    // },
    // async removeWatch() {
    //   await this.$store.dispatch('unWatchOrder', this.$route.params.id)
    // },
    // goBack() {
    //   router.go(-1)
    // },
    // onCopy: function (e) {
    //   alert('Copied to Clipboard')
    // },
  },
  // destroyed() {
  //   this.$store.dispatch('setActiveDay', null)
  // }
  // beforeDestroy () {
  //   console.log(this.activeDay)
  // }
}

</script>